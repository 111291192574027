<template>
  <b-overlay
      :show="isLoading"
      rounded="sm"
      variant="white"
      opacity="0.85"
      spinner-variant="primary"
  >
    <b-container>
      <div v-if="test" class="text-primary font-weight-bold test">
        Тест: "{{test.title}}"
        <div class="test-question">
          <ol v-if="test.questions.length>0">
            <li
                v-for="question in test.questions"
                :key="question.id"
            >
              <span v-html="question.content"></span>
              <b-form-group
                  v-for="answer in question.answers"
                  :key="answer.uuid"
              >
                <b-form-radio
                    v-model="answer.corrent"
                    :name="question.uuid"
                    @change="addAnswer(question.uuid, answer.uuid)"
                >
                  {{answer.title}}
                </b-form-radio>
              </b-form-group>
            </li>
          </ol>
        </div>
        <b-button
            :disabled="isSendDisable"
            @click="setGrades">Узнать результат
        </b-button>
        <b-modal
            :visible="modalVisible"
            id="gradesModal"
            :hide-footer="true"
            :no-close-on-backdrop="true"
            :title="testTitle"
            @close="$router.push('/tests')"
        >
          <div v-if="!grades.done">
            <p>
              По данному тестированию Вы не прошли! Вы можете попробовать ещё раз сдать тест.
            </p>
            <b-row v-if="gradesIsNotEmpty">
              <b-col cols="8">
                <p class="d-flex justify-content-between">
                  Представлено заданий: <span>{{questionsAmount}}</span>
                </p>
                <p class="d-flex justify-content-between">
                  Верных ответов: <span>{{rightAnswersAmount}}</span>
                </p>
                <p class="d-flex justify-content-between">
                  Неверных ответов: <span>{{wrongAnswersAmount}}</span>
                </p>
              </b-col>
            </b-row>
          </div>
          <template v-else>
            <div class="d-flex align-items-center">
              <b-icon
                  icon="check-circle-fill"
                  font-scale="2"
                  variant="success"
              ></b-icon>
              <p class="mb-0 ml-2">Поздравляем, Вы прошли тест!</p>
            </div>
            <p>
              Ваш <router-link to="/profile">сертификат</router-link>
              будет доступен в личном кабинете.
            </p>
            <b-row>
              <b-col cols="8">
                <p class="d-flex justify-content-between">
                  Представлено заданий: <span>{{questionsAmount}}</span>
                </p>
                <p class="d-flex justify-content-between">
                  Верных ответов: <span>{{rightAnswersAmount}}</span>
                </p>
                <p class="d-flex justify-content-between">
                  Неверных ответов: <span>{{wrongAnswersAmount}}</span>
                </p>
              </b-col>
            </b-row>
          </template>
          <div class="d-flex justify-content-between">
          </div>
          <div
              v-if="!grades.done"
              class="d-flex justify-content-end"
          >
            <b-button @click="reloadPage">Попробовать еще</b-button>
          </div>
        </b-modal>
      </div>
    </b-container>
  </b-overlay>
</template>

<script>
import { getTestQuestions, setGrades } from '../api/profi_club/tests';

export default {
  name: 'Test',
  data() {
    return {
      test: null,
      result: {},
      grades: {},
      modalVisible: false,
      isCorrect: false,
      isLoading: true,
    };
  },
  computed: {
    gradesIsNotEmpty() {
      return Object.keys(this.grades).length > 0;
    },
    testTitle() {
      return (this.test) ? `Результаты теста: "${this.test.title}"` : '';
    },
    questionsAmount() {
      if (this.test && this.test.questions && this.test.questions) {
        return this.test.questions.length;
      }
      return 0;
    },
    rightAnswersAmount() {
      if (this.test !== null && this.test.questions && this.test.questions.length > 0) {
        console.log(this.test.questions);
        return this.grades.result.questions.filter((item) => item.answer.correct === true).length;
      }
      return 0;
    },
    wrongAnswersAmount() {
      if (this.test !== null && this.test.questions && this.test.questions.length > 0) {
        return this.grades.result.questions.filter((item) => item.answer.correct === false).length;
      }
      return 0;
    },
    isSendDisable() {
      if (this.test !== null && this.test.questions && this.test.questions.length > 0) {
        return Object.keys(this.result).length < this.test.questions.length;
      }
      return false;
    },
  },
  methods: {
    reloadPage() {
      window.location.reload();
    },
    addAnswer(key, id) {
      const newRes = {};
      newRes[key] = id;
      this.result = { ...this.result, ...newRes };
    },
    async setGrades() {
      this.grades = await setGrades(this.result, this.test.uuid);
      this.modalVisible = true;
    },
  },
  async created() {
    const test = await getTestQuestions(this.$route.params.id);
    this.test = test;
    this.isLoading = false;
  },
};
</script>

<style scoped lang="scss">
.test{
  margin-top: 60px;
  overflow: hidden;
  margin-bottom: 112px;
  .test-question{
    margin-top: 40px;
    ul{
      list-style-type: none;
    }
  }
}
</style>
