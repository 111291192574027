import { axiosProfiClubApiWithCredentials } from '../index';

export async function getTests() {
  const urlSegment = 'tests';
  const response = await axiosProfiClubApiWithCredentials.get(urlSegment);
  return response.data ? response.data : null;
}
export async function getGrades() {
  const urlSegment = 'tests/grades';
  const response = await axiosProfiClubApiWithCredentials.get(urlSegment);
  return response.data ? response.data : null;
}

export async function setGrades(result, testUuid) {
  const urlSegment = 'tests/grades';
  const response = await axiosProfiClubApiWithCredentials.post(urlSegment, { result, testUuid });
  return response.data ? response.data : null;
}

export async function getTestQuestions(id) {
  const urlSegment = `tests/${id}`;
  const response = await axiosProfiClubApiWithCredentials.get(urlSegment);
  return response.data ? response.data : null;
}
